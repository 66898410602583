<template>
  <div class="menu-type" v-if="categories">
    <div
      class="menu-type__item"
      v-for="(category, index) in categories"
      :key="category"
      :class="{ 'menu-type__item--selected': index === selectedMenuIndex }"
      @click="selectMenuItem(index)"
    >
      <router-link :to="'/' + slug" class="menu-type__btn">
        {{ category }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "RestaurantMenuType",
  props: {
    categories: {
      type: Array,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore();

    const selectedMenuIndex = computed(() => {
      return store.state.restaurant.selectedMenuIndex;
    });

    const selectMenuItem = (index) => {
      store.commit("restaurant/updateSelectedMenuIndex", index);
    };

    return {
      selectedMenuIndex,
      selectMenuItem,
    };
  },
};
</script>



<style scoped>
.menu-type {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px 0 32px 0;
}
.menu-type__btn {
  cursor: pointer;
  background-color: var(--color-white);
  border: 3px solid var(--color-primary);
  border-radius: 32px;
  padding: 5px 10px 5px;
  color: var(--color-primary);
  font-weight: 600;
  line-height: 1.15;
  font-size: 16px;
  transition: all 0.4s ease;
  text-decoration: none;
}

.menu-type__item:not(:last-of-type) {
  margin-right: 8px;
}

.menu-type__item--selected .menu-type__btn,
.menu-type__item:hover .menu-type__btn {
  background-color: var(--color-primary);
  color: var(--color-white);
}
</style>