<template>
  <div>
    <div v-if="getRestaurant && isLoading">
      <HeroApp
        v-if="getRestaurant"
        :hero="getRestaurant.featured_image"
      ></HeroApp>
      <MenuWrap v-if="getRestaurant" :key="getRestaurant.id">
        <router-link :to="'/' + restaurantSlug" class="back-button focus">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.41412 13L12.707 19.2929L11.2928 20.7071L2.58569 12L11.2928 3.29289L12.707 4.70711L6.41412 11H20.9999V13H6.41412Z"
              fill="var(--color-black)"
            ></path>
          </svg>
        </router-link>
        <RestaurantDetails
          :title="getRestaurant.title"
          :address="getRestaurant.address"
          :phone="getRestaurant.phone"
          :password="getRestaurant.password"
        >
        </RestaurantDetails>

        <RestaurantMenuType
          :slug="restaurantSlug"
          :categories="getRestaurant.categories"
        ></RestaurantMenuType>

        <RestaurantWrap>
          <div v-if="getRestaurant.catName" class="restaurant-menu__title">
            <h3>{{ getRestaurant.catName }}</h3>
          </div>
          <div
            v-for="(parentCat, index) in getRestaurant.categories"
            :key="parentCat"
          >
            <div v-show="index == selectedMenuIndex">
              <RestaurantMenuItem
                v-for="category in getRestaurant.menu"
                :key="category.ID"
                :title="category.post_title"
                :description="category.post_excerpt"
                :price="category.price"
                :quantity="category.quantity"
                :image="category.featured_image_url"
              ></RestaurantMenuItem>
            </div>
          </div>
        </RestaurantWrap>
        <div class="copyright__footer">
          <p><a href="#">W3 Lab</a> & <a href="#">CodeBears</a></p>
        </div>
      </MenuWrap>
    </div>
    <div v-else-if="!isLoading">
      <h1 class="not-exist-h1">404</h1>
      <p class="not-exist">Error: Restaurant not found</p>
    </div>
    <div v-else class="preloader">
      <div class="spinner-chase">
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import HeroApp from "../components/HeroApp.vue";
import MenuWrap from "../components/MenuWrap.vue";
import RestaurantDetails from "../components/restaurant/RestaurantDetails.vue";
import RestaurantMenuType from "../components/restaurant/RestaurantMenuType.vue";
import RestaurantWrap from "../components/restaurant/RestaurantWrap.vue";
import RestaurantMenuItem from "../components/restaurant/RestaurantMenuItem.vue";

import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ref } from "vue";

export default {
  name: "RestaurantMenu",

  components: {
    HeroApp,
    MenuWrap,
    RestaurantDetails,
    RestaurantMenuType,
    RestaurantWrap,
    RestaurantMenuItem,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(true);
    const restaurantSlug = route.params.restaurantSlug;
    const getRestaurant = computed(
      () => store.getters["restaurant/getRestaurant"]
    );

    const selectedMenuIndex = computed(() => {
      return store.getters["restaurant/getActiveMenu"];
    });

    const loadRestaurant = async (restaurantSlug, menuId) => {
      isLoading.value = false;

      try {
        store.dispatch("restaurant/loadRestaurant", {
          restaurantSlug: restaurantSlug,
          menuId: menuId,
        });
      } catch (error) {
        console.error(error);
      }
      isLoading.value = true;
    };

    const activeMenu = computed(() => {
      return store.state.activeMenuType;
    });

    onMounted(() => {
      const restaurantSlug = route.params.restaurantSlug;
      const menuId = route.params.menuId;

      loadRestaurant(restaurantSlug, menuId);
    });

    return {
      getRestaurant,
      selectedMenuIndex,
      activeMenu,
      isLoading,
      restaurantSlug,
    };
  },
};
</script>
  

<style scoped>
.restaurant-menu__title {
  margin-bottom: 18px;
}

.restaurant-menu__title h3 {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #4c4c4c;
  line-height: 1.4;
}
</style>