<template>
  <div class="restaurant-list">
    <slot></slot>
  </div>
</template>


<script>
export default {
  name: "RestaurantWrap",
};
</script>