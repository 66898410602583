export default {
    setRestaurant(state, restaurant) {
        state.restaurant = restaurant;
    },
    setError(state, error) {
        state.error = error;
    },
    updateSelectedMenuIndex(state, payload) {
        state.selectedMenuIndex = payload;
    }


}