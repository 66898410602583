import { createRouter, createWebHistory } from 'vue-router';
import RestaurantSingle from './views/RestaurantSingle.vue';
import RestaurantMenu from './views/RestaurantMenu.vue';
import NotFound from './views/NotFound.vue';


const routes = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/:restaurantSlug', name: 'Restaurant', component: RestaurantSingle,

        },
        {
            path: '/:restaurantSlug/:menuId', name: 'RestaurantMenu', component: RestaurantMenu

        },
        {
            path: '/:catchAll(.*)',
            component: NotFound,
        },
    ]
});


export default routes;
