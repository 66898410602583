<template>
  <div class="restaurant-menu">
    <div v-if="image" class="restaurant-menu__image">
      <img :src="imageUrlBase + image" alt="" />
    </div>
    <div class="restaurant-menu__details">
      <div class="restaurant-menu__details__title">
        <h3 v-if="title">{{ title }}</h3>
        <div v-if="quantity" class="restaurant-menu__details__weight">
          {{ quantity }}
        </div>
      </div>
      <div v-if="description" class="restaurant-menu__details__description">
        <p>{{ description }}</p>
      </div>
      <div v-if="price" class="restaurant-menu__details__price">
        {{ price }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "title", "description", "image", "price", "quantity"],
  name: "RestaurantMenuItem",
  setup() {
    const imageUrlBase = process.env.VUE_APP_IMAGE_BASE;
    return {
      imageUrlBase,
    };
  },
};
</script>

<style scoped>
.restaurant-menu:not(:last-of-type) {
  margin-bottom: 56px;
}

.restaurant-menu__image {
  margin-bottom: 10px;
  aspect-ratio: 1.61/1;
}
.restaurant-menu__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 26px;
}

.restaurant-menu__details__title {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

.restaurant-menu__details__title h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  color: #4c4c4c;
  margin: 0 0 6px 0;
  flex: 1;
}

.restaurant-menu__details__weight {
  color: #989898;
  font-size: 14px;
  flex-shrink: 0;
  white-space: nowrap;
  margin-left: 16px;
}

.restaurant-menu__details__description {
  position: relative;
  margin-bottom: 6px;
  color: #676767;
  font-size: 14px;
}

.restaurant-menu__details__description p {
  margin: 0;
  line-height: 1.46;
}

.restaurant-menu__details__price {
  font-weight: 500;
  line-height: 1;
  font-size: 24px;
  color: var(--color-primary);
  display: flex;
}

.restaurant-menu__details__price span {
  font-size: 16px;
  font-weight: 400;
  margin-left: 2px;
}
</style>