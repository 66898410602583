<template>
  <div class="menu-wrap">
    <slot></slot>
  </div>
</template>


<script>
export default {
  name: "MenuWrap",
};
</script>


<style scoped>
.menu-wrap {
  background-color: var(--color-white);
  border-radius: 24px 24px 0 0;
  margin-top: -32px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  padding: 24px 16px 0px 16px;
  position: relative;
  z-index: 1;
}
</style>