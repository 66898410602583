<template>
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>



<style>
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
:root {
  --color-white: #fff;
  --color-black: #000;
  --color-red: #ff5722;
  --color-warning: #ff9800;
  --color-primary: #f7906c;
  --color-primary-1: #ff9a76;
  --color-primary-4: #fde8db;
  --color-primary-5: #fff3eb;
  --color-text: #4c4c4c;
  --color-gray-500: #676767;
  --color-gray-text: #bababa;
  --color-gray-stroke: #d2d2d2;
  --color-gray-hover: #f2f2f2;
  --color-gray-bg: #fafafa;
}

* {
  font-family: "Rubik", sans-serif;
}
body {
  margin: 0;
  padding: 0;
  background-color: var(--color-gray-bg);
}
.container {
  max-width: 560px;
  margin: 0 auto;
}

.copyright__footer {
  padding: 16px 0px;
}

.copyright__footer p {
  margin: 0;
  font-size: 12px;
  text-align: center;
  color: var(--color-gray-text);
}

.copyright__footer a {
  color: var(--color-gray-text);
  text-decoration: none;
  transition: all 0.3s ease;
}

.copyright__footer a:hover {
  color: var(--color-primary);
}

.preloader {
  padding: 100px 0px;
}

.spinner-chase {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-animation: spinner-chase 2.5s infinite linear both;
  animation: spinner-chase 2.5s infinite linear both;
}
.chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: chase-dot 2s infinite ease-in-out both;
  animation: chase-dot 2s infinite ease-in-out both;
}
.chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: var(--color-primary);
  border-radius: 100%;
  -webkit-animation: chase-dot-before 2s infinite ease-in-out both;
  animation: chase-dot-before 2s infinite ease-in-out both;
}
.chase-dot:nth-child(1) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.chase-dot:nth-child(1):before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.chase-dot:nth-child(2) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.chase-dot:nth-child(2):before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.chase-dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.chase-dot:nth-child(3):before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.chase-dot:nth-child(4) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.chase-dot:nth-child(4):before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.chase-dot:nth-child(5) {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.chase-dot:nth-child(5):before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.chase-dot:nth-child(6) {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.chase-dot:nth-child(6):before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.not-exist-h1 {
  font-size: 72px;
  margin: 40px 0 20px 0;
  color: var(--color-primary);
  text-align: center;
  padding: 0 24px;
}

.not-exist {
  text-align: center;
  font-size: 22px;
  margin: 0;
  padding: 0 24px;
}

.back-button {
  position: fixed;

  top: 12px;
  border: none;
  background: var(--color-white);
  border-radius: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-2);
  cursor: pointer;
}

.back-button svg {
  width: 20px;
  height: 20px;
}
@-webkit-keyframes spinner-chase {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-chase {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes chase-dot {
  100%,
  80% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes chase-dot {
  100%,
  80% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>