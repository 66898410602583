import axios from "axios";

export default {
    async loadRestaurant(context, {
        restaurantSlug: restaurantSlug,
        menuId: menuId,
    }) {

        try {

            const baseUrlApi = process.env.VUE_APP_API_BASE;
            const response = await axios.get(`${baseUrlApi}restaurant/${restaurantSlug}`);
            const responseData = response.data;
            let menuData = [];
            let menuCategoryName = '';

            if (response.status !== 200) {
                throw new Error(responseData.status === 200 ? "Failed to fetch!" : responseData.statusText);
            }

            if (menuId) {

                const menuItems = Object.values(responseData.menu);
                menuItems.forEach(child => {
                    const childItems = Object.values(child);
                    const filteredMenuItems = childItems.filter(menu => {
                        return menu.child_id === menuId;
                    });

                    filteredMenuItems.forEach(menu => {
                        menuData = menu.data;
                        menuCategoryName = menu.child_cat_name;
                    });
                });

            } else {
                menuData = responseData.menu;
            }


            const restaurant = {
                id: responseData.restaurant_info[0].post_id,
                title: responseData.restaurant_info[0].post_title,
                slug: responseData.restaurant_info[0].post_name,
                featured_image: responseData.restaurant_info[0].featured_image_url,
                address: responseData.restaurant_info[0].address,
                phone: responseData.restaurant_info[0].phone_number,
                password: responseData.restaurant_info[0].wi_fi_password,
                categories: Object.keys(responseData.menu),
                catName: menuCategoryName,
                menu: menuData
            };
            context.commit("setRestaurant", restaurant); // Wrap the restaurant in an array

        } catch (error) {
            const customError = {
                message: "Restaurant not found",
                code: "RESTAURANT_NOT_FOUND",
                loading: true
            };
            context.commit("setError", customError.loading);

        }
    }
}
