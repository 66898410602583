<template>
  <div class="restaurant-banner" v-if="hero">
    <img :src="hero" alt="" />
  </div>
  <div class="restaurant-banner" v-else>
    <img src="@/assets/restaurant-placeholder.jpeg" alt="" />
  </div>
</template>

<script>
export default {
  props: ["hero"],
  name: "HeroApp",
};
</script>

<style scoped>
.restaurant-banner {
  height: 162px;
}
.restaurant-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>