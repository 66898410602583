<template>
  <div class="not-found">
    <h1>404</h1>
    <p>Restaurant not found. Try Again.</p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  setup() {},
};
</script>

<style scoped>
.not-found {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
}

.not-found h1 {
  font-size: 72px;
  margin: 0 0 20px 0;
  color: var(--color-primary);
}

.not-found p {
  font-size: 22px;
  margin: 0;
}
</style>