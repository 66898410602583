import { createStore } from 'vuex';

import restaurantsModule from './modules/restaurant/index.js';


const store = createStore({
    modules: {
        restaurant: restaurantsModule,
    },
});

export default store;