<template>
  <div class="restaurant-info">
    <h1 v-if="title" class="restaurant-info__title">{{ title }}</h1>
    <ul class="restaurant-info__list" v-if="address || phone || password">
      <li class="restaurant-info__box" v-if="address">
        <img src="@/assets/img/icon/location.svg" alt="" />
        <span>
          <a href="#">{{ address }}</a>
        </span>
      </li>
      <li class="restaurant-info__box" v-if="phone">
        <img src="@/assets/img/icon/phone.svg" alt="" />
        <span>
          <a href="#">{{ phone }}</a>
        </span>
      </li>
      <li class="restaurant-info__box" v-if="password">
        <img src="@/assets/img/icon/wi-fi.svg" alt="" />
        <span>
          <span>{{ password }}</span>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "RestaurantDetails",
  props: {
    title: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      required: false,
    },
    phone: {
      type: String,
      required: false,
    },
    password: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.restaurant-info__title {
  margin: 0 0 16px 0;
  font-weight: 400;
  color: var(--color-black);
}

.restaurant-info__list {
  padding-left: 0;
  margin: 4px 0px 8px 0px;
  color: #989898;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.restaurant-info__box {
  display: flex;
  align-items: center;
  margin: 0 12px 8px 0;
}

.restaurant-info__box img {
  margin-right: 4px;
}

.restaurant-info__box a {
  text-decoration: none;
  color: #989898;
}
</style>