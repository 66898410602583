<template>
  <div>
    <div v-if="getRestaurant && isLoading">
      <HeroApp
        v-if="getRestaurant"
        :hero="getRestaurant.featured_image"
      ></HeroApp>
      <MenuWrap v-if="getRestaurant" :key="getRestaurant.id">
        <RestaurantDetails
          :title="getRestaurant.title"
          :address="getRestaurant.address"
          :phone="getRestaurant.phone"
          :password="getRestaurant.password"
        >
        </RestaurantDetails>

        <RestaurantMenuType
          :slug="restaurantSlug"
          :categories="getRestaurant.categories"
        ></RestaurantMenuType>

        <RestaurantWrap>
          <div
            v-for="(parentCat, index) in getRestaurant.categories"
            :key="parentCat"
          >
            <div v-show="index == selectedMenuIndex">
              <RestaurantCategory
                v-for="(category, index) in getRestaurant.menu[parentCat]"
                :key="category"
                :id="category.child_id"
                :name="index"
                :title="index"
                :image="category.subcategory_image_url"
              ></RestaurantCategory>
            </div>
          </div>
        </RestaurantWrap>
        <div class="copyright__footer">
          <p><a href="#">W3 Lab</a> & <a href="#">CodeBears</a></p>
        </div>
      </MenuWrap>
    </div>

    <div v-else-if="error">
      <h1 class="not-exist-h1">404</h1>
      <p class="not-exist">Error: Restaurant not found</p>
    </div>
    <div v-else class="preloader">
      <div class="spinner-chase">
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroApp from "../components/HeroApp.vue";
import MenuWrap from "../components/MenuWrap.vue";
import RestaurantDetails from "../components/restaurant/RestaurantDetails.vue";
import RestaurantMenuType from "../components/restaurant/RestaurantMenuType.vue";
import RestaurantWrap from "../components/restaurant/RestaurantWrap.vue";
import RestaurantCategory from "../components/restaurant/RestaurantCategory.vue";

import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ref } from "vue";

export default {
  name: "RestaurantSingle",
  components: {
    HeroApp,
    MenuWrap,
    RestaurantDetails,
    RestaurantMenuType,
    RestaurantWrap,
    RestaurantCategory,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(true);
    const restaurantSlug = route.params.restaurantSlug;

    const error = computed(() => store.getters["restaurant/getError"]);

    const getRestaurant = computed(
      () => store.getters["restaurant/getRestaurant"]
    );

    const selectedMenuIndex = computed(() => {
      return store.getters["restaurant/getActiveMenu"];
    });

    const loadRestaurant = async (restaurantSlug) => {
      isLoading.value = false;
      try {
        store.dispatch("restaurant/loadRestaurant", {
          restaurantSlug: restaurantSlug,
        });
      } catch (error) {
        console.error(error);
      }
      isLoading.value = true;
    };

    const activeMenu = computed(() => {
      return store.state.activeMenuType;
    });

    onMounted(() => {
      const restaurantSlug = route.params.restaurantSlug;
      loadRestaurant(restaurantSlug);
    });

    return {
      getRestaurant,
      selectedMenuIndex,
      activeMenu,
      isLoading,
      restaurantSlug,
      error,
    };
  },
};
</script>
