import restaurantsMutations from './mutations.js';
import restaurantsActions from './actions.js';
import restaurantsGetters from './getters.js';

export default {
    namespaced: true,
    state() {
        return {
            restaurant: null,
            isLoading: false,
            error: false,
            selectedMenuIndex: 0
        };
    },
    mutations: restaurantsMutations,
    actions: restaurantsActions,
    getters: restaurantsGetters,
};