<template>
  <div class="restaurant-list__item" v-if="title || image">
    <router-link
      class="restaurant-list__link"
      :to="{
        path: `/${restaurantSlug}/${id}`,
        props: title,
      }"
      :style="{ backgroundImage: 'url(' + imageUrlBase + image + ')' }"
    >
      <h2 class="restaurant-list__name">{{ title }}</h2>
    </router-link>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
export default {
  props: ["id", "title", "image"],
  name: "RestaurantCategory",
  setup() {
    const showRestaurantWrap = ref(false);
    const showCategory = ref(false);
    const restaurantSlug = useRoute().params.restaurantSlug;
    const imageUrlBase = process.env.VUE_APP_IMAGE_BASE;

    const toggleRestaurantWrap = (e) => {
      e.preventDefault();
      showRestaurantWrap.value = !showRestaurantWrap.value;
      showCategory.value = true;
    };

    return {
      toggleRestaurantWrap,
      showRestaurantWrap,
      showCategory,
      restaurantSlug,
      imageUrlBase,
    };
  },
};
</script>
  
  <style scoped>
.restaurant-menu__title {
  margin-bottom: 18px;
}

.restaurant-menu__title h3 {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #4c4c4c;
  line-height: 1.4;
}
.restaurant-list {
  padding-bottom: 24px;
}
.restaurant-list__item {
  aspect-ratio: 3/1;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.restaurant-list__item:not(:last-of-type) {
  margin-bottom: 16px;
}

.restaurant-list__link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  color: #fff;
  border-radius: 26px;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.46);
  box-shadow: var(--shadow-1);
  background-size: cover;
  background-position: center;
  text-decoration: none;
}

.restaurant-list__link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 26px;
  background: #252525;
  opacity: 0.3;
}

.restaurant-list__name {
  font-size: 28px;
  position: relative;
  text-transform: uppercase;
  margin: 20px 0 18px;
  font-weight: 400;
}
</style>